'use client'
import '@general/styles/components/home/home_hero.scss'

import { useTranslation } from '@general/i18n/client'
import { RootState } from '@general/redux/store'
import { useSelector } from 'react-redux'
import { ContactFormModal } from '@general/components/client_side/ContactFormModal';
import { useState } from 'react';
import { Trans } from 'react-i18next'


export function HomeHero(): React.ReactElement {
    const selectedLang = useSelector((state: RootState) => state.lang.value);
    const { t } = useTranslation(selectedLang, 'home');
    const [modalContactFormShow, setModalContactFormShow] = useState(false);
    return <>
        <ContactFormModal
            show={modalContactFormShow}
            onHide={() => setModalContactFormShow(false)}
            place_from="home_hero"
        />
        <div className="home-hero">
            <div className="wave z-0"></div>
            <div className="container position-relative z-1">
                <div className="row">
                    <div className="col-xl-6">
                        <div className="hero-text-wrapper">
                            <div className="hero-text-block">
                                <h1 className="text-white hero-title">{t('Magic Touch in Web Development', { ns: 'home' })}</h1>
                                <div className="text-white hero-sub-title">
                                    <Trans t={t} ns='home'>
                                        WM Web Agency is here to help you with <span className="no-break">E-commerce</span> implementation
                                    </Trans>
                                </div>
                                <button className='btn btn-dark mt-5' onClick={() => setModalContactFormShow(true)}>{t('Contact us' , { ns: 'home' })}</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="phone-emulated-wrapper">
                            <div className="phone-emulated">
                                <div className="web-development" data-aos="zoom-in" data-aos-duration="500" >
                                    <div className="figure-text text-white">{t('Web Development', { ns: 'home' })}</div>
                                </div>
                                <div className="erp-development" data-aos="zoom-in" data-aos-duration="500">
                                    <div className="figure-text text-white">{t('ERP/CRM', { ns: 'home' })}</div>
                                </div>
                                <div className="devops-development" data-aos="zoom-in" data-aos-duration="500">
                                    <div className="figure-text text-white">{t('DevOps', { ns: 'home' })}</div>
                                </div>
                                <div className="ads-development" data-aos-delay="500" data-aos="zoom-in">
                                    <div className="figure-text text-white">{t('Advertising', { ns: 'home' })}</div>
                                </div>
                                <div className="since-text text-light">{t('Since 2012', { ns: 'home' })}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}
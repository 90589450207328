'use client'
import { useTranslation } from '@general/i18n/client'
import { RootState } from '@general/redux/store'
import { useSelector } from 'react-redux'
import { ContactFormModal } from '@general/components/client_side/ContactFormModal';
import { useState } from 'react';

export function ContactFormButton({title, placeFrom}:{title?: string, placeFrom?: string}): React.ReactElement {
    const selectedLang = useSelector((state: RootState) => state.lang.value);
    const { t } = useTranslation(selectedLang, 'common');
    const [modalContactFormShow, setModalContactFormShow] = useState(false);
    const buttonTitle = title || t('Contact now');
    return <>
        <ContactFormModal
            show={modalContactFormShow}
            onHide={() => setModalContactFormShow(false)}
            place_from={placeFrom}
        />
        <button className="btn btn-primary action-button mt-5" onClick={() => setModalContactFormShow(true)}>{buttonTitle}</button>
    </>
}
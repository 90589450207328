import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/wmweb/nextjs/components/client_side/AOS.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CookieBanner"] */ "/home/wmweb/nextjs/components/client_side/CookieBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/home/wmweb/nextjs/components/client_side/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/home/wmweb/nextjs/components/client_side/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Ecommerce"] */ "/home/wmweb/nextjs/components/client_side/home/Ecommerce.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Erp"] */ "/home/wmweb/nextjs/components/client_side/home/Erp.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HomeHero"] */ "/home/wmweb/nextjs/components/client_side/home/Hero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OurStack"] */ "/home/wmweb/nextjs/components/client_side/home/OurStack.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WhoTrust"] */ "/home/wmweb/nextjs/components/client_side/home/WhoTrust.tsx");

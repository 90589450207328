'use client'
import '@general/styles/components/progress_line.scss'

export function ProgressLine({ title, color, persentage }: { title: string, color: string, persentage: number }): React.ReactElement {
    const value = persentage + '%';
    return <>
        <div className="progress-line-block">
            <div className="title">{title}</div>
            <div className="line_wrapper">
                <div className="main">
                    <div className="progress"
                        style={{ backgroundColor: color, width: value }}
                    ></div>
                </div>
                <div className="value"
                    style={{ left: value }}
                    >{value}</div>
            </div>
        </div>
    </>
}
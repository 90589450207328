'use client'

import { useTranslation } from '@general/i18n/client'
import { RootState } from '@general/redux/store'
import { useSelector } from 'react-redux'
import { StackLogoImage } from '@general/components/client_side/common/StackLogoImage'

import prestashopLogo from '@general/assets/home/prestashop_logo.svg'
import odooLogo from '@general/assets/home/odoo_logo.svg'
import magentoLogo from '@general/assets/home/magento_logo.svg'
import nextJsLogo from '@general/assets/home/nextjs_logo.svg'
import reactLogo from '@general/assets/home/react_logo.svg'
import threeJSLogo from '@general/assets/home/rhree_js_logo.svg'
import goFiberLogo from '@general/assets/home/gofiber_logo.svg'
import linuxLogo from '@general/assets/home/linux_logo.svg'
import dockerLogo from '@general/assets/home/docker_logo.svg'
import javaScriptLogo from '@general/assets/home/js_logo.svg'
import phpLogo from '@general/assets/home/php_logo.svg'
import golangLogo from '@general/assets/home/golang_logo.svg'
import pythonLogo from '@general/assets/home/python_logo.svg'

export function OurStack(): React.ReactElement {
    const selectedLang = useSelector((state: RootState) => state.lang.value);
    const { t } = useTranslation(selectedLang, 'home');
    return <>
        <div className="our-stack pt-6">
            <div className="container text-center">
                <h3 className="h-title pb-5 text-center">{t('Our Technologies', { ns: 'home' })}</h3>
                <div className="our-stack-list">
                    <StackLogoImage src={prestashopLogo.src}  alt={t('PrestaShop', { ns: 'common' })}/>
                    <StackLogoImage src={odooLogo.src}  alt={t('Odoo', { ns: 'common' })}/>
                    <StackLogoImage src={magentoLogo.src}  alt={t('Magento', { ns: 'common' })}/>
                    <StackLogoImage src={nextJsLogo.src}  alt={t('NextJS', { ns: 'common' })}/>
                    <StackLogoImage src={reactLogo.src}  alt={t('React', { ns: 'common' })}/>
                    <StackLogoImage src={threeJSLogo.src}  alt={t('ThreeJS', { ns: 'common' })}/>
                    <StackLogoImage src={goFiberLogo.src}  alt={t('Go Fiber', { ns: 'common' })}/>
                    <StackLogoImage src={linuxLogo.src}  alt={t('Linux', { ns: 'common' })}/>
                    <StackLogoImage src={dockerLogo.src}  alt={t('Docker', { ns: 'common' })}/>
                    <StackLogoImage src={javaScriptLogo.src}  alt={t('JavaScript', { ns: 'common' })}/>
                    <StackLogoImage src={phpLogo.src}  alt={t('PHP', { ns: 'common' })}/>
                    <StackLogoImage src={golangLogo.src}  alt={t('GoLang', { ns: 'common' })}/>
                    <StackLogoImage src={pythonLogo.src}  alt={t('Python', { ns: 'common' })}/>
                </div>
            </div>
        </div>
    </>
}
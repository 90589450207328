'use client'

import { useTranslation } from '@general/i18n/client'
import { RootState } from '@general/redux/store'
import { useSelector } from 'react-redux'
import { ContactFormButton } from '@general/components/client_side/common/ContactFormButton'
import { Trans } from 'react-i18next';
import { ProgressLine } from '@general/components/client_side/common/ProgressLine'
import Image from 'next/image'
import ecommerceIcon from '@general/assets/home/ecommerce.svg'

export function Ecommerce(): React.ReactElement {
    const selectedLang = useSelector((state: RootState) => state.lang.value);
    const { t } = useTranslation(selectedLang, 'home');
    return <>
        <div className="container home-ecommerce pt-6">
            <div className="row">
                <div className="col-xl-6">
                    <div className="content-block" data-aos="fade-right">
                        <h2 className="small-title">
                            <Image src={ecommerceIcon.src}
                                className="float-left me-1 mt-1"
                                height="72"
                                width="70"
                                alt={t('E-commerce', { ns: 'home' })} loading="lazy"
                            /> {t('E-commerce development for profitable stores', { ns: 'home' })}
                        </h2>
                        <p className="primary-text pt-5">
                            <Trans t={t} ns='home'>
                                We build fast, affordable, SEO-friendly online stores using <strong>PrestaShop</strong> or <strong>Magento</strong> CMS, so our customers can launch their own stores quickly without breaking the bank. Our stores load in less than 0.26 seconds, handle over 100 000 products, and support more than 1 000 clients at the same time.
                            </Trans>
                        </p>
                    </div>

                </div>
                <div className="col-xl-1"></div>
                <div className="col-xl-5">
                    <div className="chars-block" data-aos="fade-left">
                        <ProgressLine
                            title={t('SEO-friendly', { ns: 'home' })}
                            color="#8cbb1c"
                            persentage={99}
                        />
                        <ProgressLine
                            title={t('Hight Performance', { ns: 'home' })}
                            color="#8cbb1c"
                            persentage={90}
                        />
                        <ProgressLine
                            title={t('Save Money', { ns: 'home' })}
                            color="#e69e4c"
                            persentage={50}
                        />
                        <ProgressLine
                            title={t('Save Time', { ns: 'home' })}
                            color="#e69e4c"
                            persentage={50}
                        />
                    </div>
                </div>
            </div>
            <div className="text-center pt-5">
                <ContactFormButton title={t('Order shop now', { ns: 'common' })} placeFrom='home_ecommerce' />
            </div>
        </div>
    </>
}
'use client'

import { useTranslation } from '@general/i18n/client'
import { RootState } from '@general/redux/store'
import { useSelector } from 'react-redux'
import { ContactFormButton } from '@general/components/client_side/common/ContactFormButton'
import { Trans } from 'react-i18next';
import Image from 'next/image'
import erpIcon from '@general/assets/home/erp_icon.svg'
import erpMainScheme from '@general/assets/home/erp_main_scheme.webp'

export function Erp(): React.ReactElement {
    const selectedLang = useSelector((state: RootState) => state.lang.value);
    const { t } = useTranslation(selectedLang, 'home');
    return <>
        <div className="container home-erp pt-6">
            <div className="row">
                <div className="col-xl-6">
                    <div className="content-block" data-aos="fade-right">
                        <h2 className="small-title">
                            <Image src={erpIcon.src}
                                className="float-left me-1 mt-1"
                                height="72"
                                width="70"
                                alt={t('ERP/CRM', { ns: 'home' })} loading="lazy"
                            /> {t('Developing ERP/CRM That Helps You Rule Your Business', { ns: 'home' })}
                        </h2>
                        <p className="primary-text pt-5">
                            <Trans t={t} ns='home'>
                                We use <strong>Odoo ERP</strong> to deliver everything you need: sales funnel management, customer support, task tracking, marketing, inventory management, VoIP, HR, accounting, and more. It’s open-source, highly customizable, and stores your data securely in a place you choose. No per-user fees—your entire team can use it without extra costs. <strong>Access it from anywhere in the world.</strong>
                            </Trans>
                        </p>
                    </div>

                </div>
                <div className="col-xl-1"></div>
                <div className="col-xl-5">
                    <div className="image-block d-flex justify-content-center justify-content-xl-end" data-aos="fade-left">
                        <Image src={erpMainScheme.src}
                            height="400"
                            width="400"
                            alt={t('ERP/CRM', { ns: 'home' })} loading="lazy"
                        />
                    </div>
                </div>
            </div>
            <div className="text-center pt-5">
                <ContactFormButton title={t('Order erp now', { ns: 'common' })} placeFrom='home_erp' />
            </div>
        </div>
    </>
}
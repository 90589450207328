'use client';

import { useEffect } from 'react';
import '@general/styles/components/aos.scss'
export default function AOS(): null {
  useEffect(() => {
    import('aos').then((AOS) => {
      AOS.init({
        once: true,
        mirror: false,
      });
    });
  }, []);

  return null;
}